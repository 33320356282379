import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
      <div className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <h1 className="text-center font-mincho text-2xl text-primary">
          ページが見つかりません。
        </h1>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
